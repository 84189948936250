import React from "react";
import "./Footer.css";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <React.Fragment>
      <Col md={12} className="d-flex justify-content-center">
        <Link to="/privacy-policy" className="mx-2">
          Privacy Policy
        </Link>
        <Link to="/terms" className="mx-2">
          Terms of Use
        </Link>
      </Col>
      <br />
    </React.Fragment>
  );
};
export default Footer;
