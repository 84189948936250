import React, { useState, useEffect } from "react";
import FormSignup from "./FormSignup";
import FormSuccess from "./FormSuccess";
import "./Form.css";
import modalPosition from "./ModalPosition";

const Form = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }

  useEffect(() => {
    modalPosition();
  }, []);

  return (
    <React.Fragment>
      <div
        className="registration-form-container"
        data-aos="fade-in"
        data-aos-delay="400"
      >
        <div className="form-design">
          {!isSubmitted ? (
            <FormSignup isCourier={props.isCourier} submitForm={submitForm} />
          ) : (
            <FormSuccess />
          )}
          {/* <FormSuccess /> */}
        </div>
        {/* <div className="phone-info form-display-none-sm">
          <p>אפשר גם בטלפון</p>
          <h2>
            3232<span>*</span>
          </h2>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Form;
