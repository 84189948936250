import React, { useEffect } from "react";
import "./benefits.css";
import { Image, Col } from "react-bootstrap";

import applicheckLogo from "../../assets/img/applicheck-logo.jpg";

const Benefits = (props) => {
  return (
    <React.Fragment>
      <Col md={6}>
        <div className="benefit-section " data-aos={props.dataAos}>
          <div className="benefit-right">
            <Image
              src={props.icon}
              className="benefit-icon"
              style={{ width: props.width, height: props.height }}
            />
          </div>
          <div className="benefit-left">
            <h2 className="benefit-title brand-font">{props.title}</h2>
            <span className="main-p">{props.description}</span>
            {props.show ? (
              <Image src={applicheckLogo} className="applicheck-logo" fluid />
            ) : null}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};
export default Benefits;
