export default function validateInfo(values) {
  let errors = {};

  //FULL NAME
  // if (!values.user_full_name.trim()) {
  //   errors.user_full_name = 'שדה חובה';
  // } else if (!/^[\u0590-\u05FF ,.'-]+$/i.test(values.user_full_name)) {
  //   errors.user_full_name = 'שדה זה יכול להכיל אותיות בעברית בלבד';
  // }

  //EMAIL
  // if (!values.user_email) {
  //   errors.user_email = 'email required';
  // } else if (!/\S+@\S+\.\S+/.test(values.user_email)) {
  //   errors.user_email = 'Email address is invalid';
  // }

  //PHONE NUMBER
  // if (!values.user_phone_number) {
  //   errors.user_phone_number = 'שדה חובה';
  // } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.user_phone_number)) {
  //   errors.user_phone_number = 'מספר הנייד לא תקין';
  // }

  //VEHICLE SELECTION
  // if (values.user_vehicle_type === ' ') {
  //   errors.user_vehicle_type = 'mandatory field';
  // }

  return errors;
}
