import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "./couriers.css";

import heroMainImg from "../../assets/img/truck-bg.png";

//Components
import Form from "../../components/Form/Form";
import DownloadSection from "../../components/Download-section/download-section";
import Feedbacks from "../../components/Feedbacks/feedbacks";
import Footer from "../../components/Footer/Footer";

const couriers = () => {
  return (
    <React.Fragment>
      <div className="form-wrapper">
        <section id="hero-couriers">
          <Container fluid className="pt-5">
            <Container className="pt-5">
              <Row>
                <Col md={6}>
                  <h2 className="display-5 brand-font" data-aos="zoom-out">
                    Calling all drivers — join our team!
                  </h2>
                  <p
                    className="hero-p"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="300"
                  >
                    Looking for drivers nationwide to join us. Offering
                    excellent conditions and enabling convenient work wherever
                    you are.
                  </p>
                  <Image
                    src={heroMainImg}
                    fluid
                    alt="Screenshot"
                    data-aos="fade-down"
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        {/* Main Section */}
        <section id="main">
          <Container className="pt-5">
            <div className="first-benefits">
              <h2 className="display-4 brand-font" data-aos="zoom-in">
                We Move Fast
              </h2>
              <div className="text-wrapper pb-5 mb-5">
                <div className="separate" data-aos="fade-up"></div>
                <p
                  className="main-p"
                  style={{ maxWidth: "420px" }}
                  data-aos="fade-right"
                >
                  Every minute counts. We understand this and are committed to
                  the most advanced delivery standards. That is why we
                  established a professional and reliable community of drivers
                  with which we can promise – delivery within two hours.
                </p>
              </div>
            </div>
          </Container>
        </section>
        {/* End Main Section */}

        {/* <!-- ======= clients Section ======= --> */}
        <section id="clients">
          <Container style={{ height: "40px" }} fluid></Container>
        </section>
        <Form isCourier={true} />
      </div>
      <DownloadSection />
      <Feedbacks />
      <Footer />
    </React.Fragment>
  );
};

export default couriers;
