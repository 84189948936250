import React from "react";
import { Container, Image } from "react-bootstrap";

//Success Icon
import successIcon from "../../assets/img/check.svg";

const FormSuccess = () => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center h-100">
        <Container>
          <div className="mx-auto">
            <Image
              className="mx-auto d-block"
              src={successIcon}
              alt="success icon"
              fluid
              data-aos="fade"
            />
          </div>
          <h2 className="form-display-3 text-center" data-aos="zoom-out">
            Thank you!
          </h2>
          <div className="orange-line" data-aos="fade-right"></div>
          <p className="main-p text-center pt-5" data-aos="fade-up">
            We will contact you soon{" "}
          </p>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormSuccess;
