import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <React.Fragment>
      <Navbar
        expanded={expanded}
        className="navbar fixed-top navbar-expand-lg"
        expand="lg"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <Navbar.Brand className="pull-right">
            <Link to="/">
              <img src={logo} width="135px" alt="dibble logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto bg-white">
              <Nav.Link
                onClick={() => setExpanded(false)}
                as={Link}
                to="/"
                className="pr-5"
              >
                Home{" "}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/info"
                className="pr-5"
                onClick={() => setExpanded(false)}
              >
                Who we are
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/couriers"
                className="pr-5"
                onClick={() => setExpanded(false)}
              >
                Drivers
              </Nav.Link>
              <Nav.Link
                className="pr-5"
                to="/lobby"
                as={Link}
                onClick={() => setExpanded(false)}
              >
                Management Portal{" "}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
