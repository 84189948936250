import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./feedback.css";

const Feedbacks = () => {
  return (
    <React.Fragment>
      <section id="feedbacks">
        <Container className="gray-bg py-5" fluid data-aos="fade-in">
          <Row className="justify-content-center">
            <div className="d-flex">
              <h2 className="open-quotes">"</h2>
            </div>
            <Col md={10} lg={9} xl={3}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Carousel.Caption>
                    <div className="card border-0 card-0">
                      <p className="content">
                        With dibble it's just easy for me to order what I need
                        at that moment and everything comes to me directly on
                        the website. No minimum order, no limit of products.
                        Simple, easy and comfortable.
                      </p>
                      <p className="content-name mb-0">
                        Itzik alfasi A.A real estate
                      </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <div className="card border-0 card-0">
                      <p className="content">
                        Great app! Saves a lot of time and money!! There is
                        everything and you can make an express order or order in
                        advance for a few more days - everything comes to you
                        right up to the website 💪
                      </p>
                      <p className="content-name mb-0"> David bnaya K.l.a </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <div className="card border-0 card-0">
                      <p className="content">
                        The interface is easy to use and very clear, for me and
                        others it solved many problems because the employees are
                        not mobile and every time I had to leave everything and
                        bring them things. Now I can tackle several renovations
                        at once.
                      </p>
                      <p className="content-name mb-0">
                        Avi yam, A.Y and sons inc.
                      </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
            <div className="d-flex align-items-end">
              <h2 className="close-quotes">"</h2>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Feedbacks;
