import $ from 'jquery';

const modalPosition = () => {
  $(window).scroll(function () {
    var download = $('#clients');
    var floatingDiv = $('.registration-form-container');

    if (download.length) {
      var hT = download.offset().top,
        hH = download.outerHeight(),
        wH = $(window).height(),
        wS = $(this).scrollTop();
      // console.log(hT - wH, wS);
      if (wS > hT + hH - wH) {
        floatingDiv.addClass('abs');
      } else {
        floatingDiv.removeClass('abs');
      }
    }
  });
};

export default modalPosition;
