import React from "react";
import "./download.css";
import { Image, Container, Row, Col } from "react-bootstrap";

//images
import appStore from "../../assets/img/AppStore.svg";
import googlePlay from "../../assets/img/googlePlay.svg";

const DownloadSection = () => {
  return (
    <React.Fragment>
      <section id="download">
        <Container fluid className="orange-bg p-5 wrapper" data-aos="fade-in">
          <Container>
            <Row className="justify-content-center">
              <Col sm={12}>
                <h2
                  className="display-3 text-center brand-font"
                  style={{ color: "#fff" }}
                >
                  With just a few clicks, Dibble's platform makes the world of
                  construction accessible to everyone
                </h2>
              </Col>
              <Col sm={12}>
                <h2
                  className="display-3 text-center pt-3 brand-font"
                  style={{ color: "#fff", fontSize: "22px" }}
                >
                  To download the App
                </h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <div className="pt-3">
                <Col xs={12}>
                  <a
                    href="https://apps.apple.com/il/app/dibble/id1521796518"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={appStore}
                      alt="app store badge"
                      fluid
                      className="p-3"
                    />
                  </a>
                </Col>
                <Col xs={12}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dibble.client"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={googlePlay}
                      alt="google play badge"
                      fluid
                      className="p-3"
                    />
                  </a>
                </Col>
              </div>
            </Row>
          </Container>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default DownloadSection;
