import { useState, useEffect } from 'react';
import Axios from 'axios';
import DibbleAPI from '../../api';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    user_full_name: '',
    user_phone_number: '',
    user_email: '',
    user_vehicle_type: ' ',
    user_city: '',
  });

  const [errors, setErrors] = useState({});
  const [vehicle, setVehicle] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getVehicleTypes = () => {
    let request = DibbleAPI.api.get_all_vehicle_types;
    let baseUrl = DibbleAPI.url[process.env.REACT_APP_ENV || 'development'];
    let url = baseUrl + request;
    Axios.get(
      url,
      { headers: { 'Content-Type': 'text/plain' } },
    ).then((res) => {
      setVehicle(res.data.vehicle_types);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    // console.log(values);
  };

  //CHECKS IF THERE ARE ERRORS BEFORE SUBMIT
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
      let request = DibbleAPI.api.send_lead;
      let baseUrl = DibbleAPI.url[process.env.REACT_APP_ENV || 'development'];
      let url = baseUrl + request;
        Axios.post(
        url,
        {
          subject: '⁨בקשה להצטרפות דרך האתר⁩',
          user_full_name: values.user_full_name,
          user_phone_number: parseInt(values.user_phone_number),
          user_email: values.user_email || '',
          user_type: values.user_type,
          user_city: values.user_city,
        },
        { headers: { 'Content-Type': 'text/plain' } },
      )
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [errors]);

  return { handleChange, values, handleSubmit, errors, getVehicleTypes, vehicle };
};
export default useForm;
