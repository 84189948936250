import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";

//Images
import heroMainImg from "../../assets/img/hero-img.png";
import icon1 from "../../assets/img/main-1.svg";
import icon2 from "../../assets/img/main-2.png";
import icon3 from "../../assets/img/main-3.svg";
import screens from "../../assets/img/screens.png";

//Components
import DownloadSection from "../../components/Download-section/download-section";
import Feedbacks from "../../components/Feedbacks/feedbacks";
import Form from "../../components/Form/Form";
import Footer from "../../components/Footer/Footer";

const Homepage = () => {
  return (
    <React.Fragment>
      <div className="form-wrapper">
        <section id="hero">
          <Container fluid className="pt-5">
            <Container className="pt-5">
              <Row>
                <Col md={6}>
                  <div className="display-2 brand" data-aos="fade-in">
                    Order construction materials
                    <br />
                    <strong className="display-2 brand-font">
                      as easily as takeout
                    </strong>
                    <br />
                  </div>
                  <p className="hero-p" data-aos="fade-in" data-aos-delay="400">
                    Dibble is a digital marketplace for all your construction
                    material needs, delivered to your doorstep within hours
                  </p>
                  <Image
                    src={heroMainImg}
                    fluid
                    alt="Screenshot"
                    data-aos="fade-up"
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        {/* Main Section */}
        <section id="main">
          <Container className="pt-5 pb-5">
            <h2
              className="display-4 container brand-font"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              How does it work?{" "}
            </h2>
            <Col
              md={12}
              className="d-flex"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <Image
                src={icon1}
                width="100px"
                height="100px"
                fluid
                alt="Ideal icon"
              />
              <div className="pr-3">
                <h2 className="display-3">
                  <span className="brand-font">Shop Dibble Online</span>
                </h2>
                <p className="main-p">
                  Browse from hundreds of materials from your mobile or desktop
                </p>
              </div>
            </Col>

            <Col md={12} className="py-5 d-flex" data-aos="fade-left">
              <Image
                src={icon2}
                width="100px"
                height="100px"
                alt="Stores icon"
              />
              <div className="pr-3">
                <h2 className="brand-font">Choose Delivery Time</h2>
                <p className="main-p">
                  Select 2-hour delivery or pre-order for the date and time of
                  your choice
                  <br />
                </p>
              </div>
            </Col>
            <Col className="col-12 d-flex" data-aos="fade-left">
              <Image src={icon3} width="100px" height="100px" alt="couriers " />
              <div className="pr-3">
                <h2 className="display-3">
                  <span className="brand-font">
                    Receive Materials to Your Doorstep
                  </span>
                </h2>
                <p className="main-p">
                  Dibble delivers directly to you — home, office, or jobsite
                </p>
              </div>
            </Col>
          </Container>
        </section>
        {/* End Main Section */}

        {/* <!-- ======= clients Section ======= --> */}
        <section id="clients">
          <Container
            className="gray-bg bg-light"
            fluid
            data-aos="fade-in"
            data-aos-duration="2500"
          >
            <Container className="pt-5">
              <Col md={6}>
                <h2 className="display-4">
                  <span className="brand-font">Who is Dibble for?</span>
                </h2>
                <div className="d-flex justify-content-end">
                  <p className="main-p">
                    Dibble is designed for anyone doing a construction project,
                    large or small — from fixing your deck to managing a
                    12-story commercial project. We serve project managers,
                    contractors, skilled tradespeople, as well as home
                    renovators.
                  </p>
                </div>
                <Image src={screens} fluid alt="clients icon" />
              </Col>
            </Container>
          </Container>
        </section>
        <Form />
      </div>
      <DownloadSection />
      <Feedbacks />
      <Footer />
    </React.Fragment>
  );
};

export default Homepage;
