import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/header.js";
import Intercom from "react-intercom";
import Aos from "aos";
import "aos/dist/aos.css";

//pages
import Info from "./Pages/info/Info";
import Homepage from "./Pages/index/index.jsx";
import Couriers from "./Pages/couriers/couriers";
import Lobby from "./Pages/lobby/lobby";
// import Terms from "./Pages/Terms/Terms";
import Policy from "./Pages/Policy/Policy";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Switch>
          <React.Fragment>
            <div className="wrapper">
              <Route path="/" exact component={Homepage} />
              <Route path="/info" exact component={Info} />
              <Route path="/couriers" exact component={Couriers} />
              <Route path="/lobby" exact component={Lobby} />
              {/* <Route path="/terms" exact component={Terms} /> */}
              <Route path="/privacy-policy" exact component={Policy} />
            </div>
          </React.Fragment>
        </Switch>
        <Header />
        {/* <Intercom appID="vv35glo7" /> */}
      </Router>
    </React.Fragment>
  );
}

export default App;
