const DibbleAPI = {
  url: {
    development: 'https://test.dibble.co.il/',
    test: 'https://test.dibble.co.il/',
    qa: 'https://qa.dibble.co.il/',
    production: 'https://api.dibble.co.il/',
    demo: 'https://demo.dibble.co.il/',
  },
  api: {
    /* Registrations forms */
    send_lead: "send_lead",
    /* Couriers page - All Vehicle Types */
    get_all_vehicle_types: "get_all_vehicle_types",
  },
};

export default DibbleAPI;
