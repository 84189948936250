import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "./lobby.css";

import BackImg from "../../assets/img/lobby_back.png";
import FrontImg from "../../assets/img/lobby_front.png";
import Screen1Img from "../../assets/img/screen1.png";
import Screen2Img from "../../assets/img/screen2.png";
import AppsImg from "../../assets/img/apps.svg";

//Components
import DownloadSection from "../../components/Download-section/download-section";
import Feedbacks from "../../components/Feedbacks/feedbacks";
import Footer from "../../components/Footer/Footer";

const Lobby = () => {
  return (
    <React.Fragment>
      <div className="lobby-wrapper">
        <section id="lobby">
          <Container fluid>
            <Container className="pt-5">
              <div className="lobby-image">
                <img src={FrontImg} />
              </div>
              <h2 className="display-4 brand-font" data-aos="zoom-in">
                Integrated Project Management
              </h2>
              <div className="lobby-text">
                <p
                  className="lobby-p"
                  style={{ maxWidth: "572px" }}
                  data-aos="fade-right"
                >
                  The Management Portal is a specialized system designed for
                  small to medium construction companies. Unlike other similar
                  products, it connects to your Dibble store account, providing
                  complete visibility into every item ordered including delivery
                  status, price quotes, purchasing permissions, and more.
                </p>
              </div>
              <div className="screen1-image">
                <img src={Screen1Img} />
              </div>
              <h2 className="display-4 mt-4 pt-3 brand-font" data-aos="zoom-in">
                [coming soon] AI Calculator
              </h2>
              <div className="lobby-text">
                <p
                  className="lobby-p"
                  style={{ maxWidth: "572px" }}
                  data-aos="fade-right"
                >
                  Dibble’s AI Calculator is an essential tool for estimating
                  materials at scale. This feature enables you to upload project
                  plans, specifications, measurements, and even photos, and
                  receive material estimations according to your project’s
                  requirements. For instance, if you are painting a warehouse,
                  simply upload the floorplan and receive an approximate paint
                  quantity in seconds.
                </p>
              </div>
              <div className="screen2-image mt-4">
                <img src={Screen2Img} />
              </div>
              <div className="lobby-bottom mb-4 mt-1">
                <div className="lobby-ul pt-5">
                  <h2
                    className="display-4 mt-2 pt-3 brand-font"
                    data-aos="zoom-in"
                  >
                    The benefits
                  </h2>
                  <ul data-aos="fade-right">
                    <li>Monitor and allocate project budget in real-time</li>
                    <li>
                      Get visibility into each item purchased, delivery status,
                      who signed
                    </li>
                    <li>
                      Consolidate all invoices and receipts into one place
                    </li>
                    <li>
                      Manage purchasing permissions and allocate budget per team
                      member
                    </li>
                    <li>And much more</li>
                  </ul>
                </div>
              </div>
            </Container>
          </Container>
        </section>
      </div>
      <DownloadSection />
      <Feedbacks />
      <Footer />
    </React.Fragment>
  );
};

export default Lobby;
