import React from "react";
import { Image, Container, Row } from "react-bootstrap";
import "./info.css";
// import VideoPlayer from "react-video-js-player";
import "./video.css";

//Assets2
import playIcon from "../../assets/img/play-icon.svg";
import BgImage from "../../assets/img/search-bg.png";
import applicheckIcon from "../../assets/img/applicheck.svg";
import chatIcon from "../../assets/img/chat-icon.svg";
import settingsIcon from "../../assets/img/settings-icon.svg";
import fastIcon from "../../assets/img/fast-icon.svg";

//Video

//components
import Benefit from "../../components/Benefits/benefits";
import Download from "../../components/Download-section/download-section";
import Footer from "../../components/Footer/Footer";

const Info = () => {
  // const vid = "https://youtu.be/NnLud7fzTVU";
  return (
    <React.Fragment>
      <div className="bg">
        <section id="soon">
          <Container fluid className="pt-5">
            <Container className="playerSection" data-aos="zoom-in">
              {/* <VideoPlayer src={vid} width="720" heigh="420" /> */}
              <iframe
                width="966"
                height="543"
                src="https://www.youtube.com/embed/eE58wQ9d-cI"
                title="dibble -   כל חומרי הבנייה וכלי העבודה באפליקציה אחת"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Container>
          </Container>
        </section>
        <section id="benefits">
          <Container className="benefits">
            <Container className="right" data-aos="fade-left">
              <Container className="first-benefits">
                <h2 className="display-4 brand-font" data-aos="zoom-in ">
                  Committed to innovation in the construction industry
                </h2>
                <div className="text-wrapper">
                  <div className="separate"></div>
                  <p className="main-p" style={{ maxWidth: "420px" }}>
                    Dibble is a technology company whose mission is to reduce
                    the friction around purchasing construction materials and
                    supplies. We connect our clients to local suppliers with an
                    easy-to-use interface to ensure you get the best possible
                    service.
                    <br />
                    <br />
                    It is so easy to get lost in the isles, held up by a missing
                    bolt, or resort to sending skilled laborers on shopping
                    trips that waste hundreds of dollars. Whether you are doing
                    a personal or professional construction project, we believe
                    your time is better spent doing what you do best.
                    <br />
                    <strong className="main-p brand-bold">
                      Let us help with the shopping.
                    </strong>
                  </p>
                </div>
              </Container>
              <div className="second-benefits" data-aos="zoom-in">
                <h2 className="display-4 pb-4 brand-font">
                  We’ll be there
                  <br />
                  before the paint dries.
                </h2>
                <div className="features">
                  <Row>
                    <Benefit
                      icon={fastIcon}
                      title="Unbeatable Delivery Speed"
                      description={[
                        "Receive your delivery in up to 2 hours or preorder for the time of your choosing",
                      ]}
                      width="50px"
                      height="31px"
                      dataAos="fade-right"
                    />

                    <Benefit
                      icon={settingsIcon}
                      title="One-stop-shop"
                      description={[
                        "Shop in a few clicks and store all your invoices and receipts in one place  ",
                      ]}
                      width="40px"
                      height="40px"
                      dataAos="fade-left"
                    />

                    <Benefit
                      icon={chatIcon}
                      title="24/7 Support"
                      description={[
                        "Chat with our support team whenever you need assistance",
                      ]}
                      width="40px"
                      height="38px"
                      dataAos="fade-right"
                    />
                  </Row>
                </div>
              </div>
            </Container>
            <div className="left">
              <div className="bg-image">
                <div>
                  <Image src={BgImage} fluid />
                </div>
              </div>
            </div>
          </Container>
          <Download />
          <Footer />
        </section>
      </div>
    </React.Fragment>
  );
};

export default Info;
